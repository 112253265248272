import { CopyOutlined, DeleteOutlined, MoreOutlined } from "@ant-design/icons";
import { Divider, message } from "antd";
import { Dialog, Popover } from "antd-mobile";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import JSZip from "jszip";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FacebookShareButton, LineShareButton } from "react-share";
import { queryClient } from "../..";
import { CardDetail, Header, OrderDrawer } from "../../component";
import { NAME_DISPLAY, PRODUCTION_DOMAIN, PROTOCOL } from "../../config";
import { useGetCardSeries } from "../../hooks/cardSeries/cardSeries.hooks";
import Canvas2Image from "../../plugins/canvas2image";
import { copyText } from "../../plugins/common";
import { userData } from "../../plugins/localStorage";
import { useDeleteOrder, useGetOrder } from "../OrderDetail/hooks/index.hooks";
import ActionArea from "./component/ActionArea";
import actionAreaStyles from "./component/ActionArea/style.module.scss";
import OrderPhotoDrawer from "./component/OrderPhotoDrawer";
import styles from "./style.module.scss";

const OrderDetail = () => {
  const [items, setItems] = useState([]);
  const [copyItems, setCopyItems] = useState([]);
  const [openOrderDrawer, setOpenOrderDrawer] = useState(false);

  const { history, location } = window;
  const parsed = new URLSearchParams(location.search);
  const [openOrderPhotoDrawer, setOpenOrderPhotoDrawer] = useState(
    parsed.get("isOpenDrawer") || false
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const orderId = location.pathname.split("/")[2];
  const userId = userData().userId;
  const { mutate: deleteOrder } = useDeleteOrder();
  const {
    data: orderData,
    isSuccess,
    isLoading,
    isRefetching,
  } = useGetOrder({ orderId });
  const actions =
    userId === orderData?.content?.owner_user_id
      ? [
          {
            key: "copy",
            text: (
              <span>
                <CopyOutlined style={{ marginRight: "4px" }} />
                複製訂單
              </span>
            ),
            onClick: () => {
              setCopyItems(items);
              setOpenOrderDrawer(true);
            },
          },
          {
            key: "remove",
            text: (
              <span
                style={{ color: "var(--danger6)" }}
                onClick={() => {
                  Dialog.confirm({
                    title: "確認移除訂單？",
                    closeOnAction: true,
                    closeOnMaskClick: true,
                    onConfirm: () => {
                      deleteOrder(
                        { orderId },
                        {
                          onSuccess: (data) => {
                            message.success("刪除成功");
                            setTimeout(() => {
                              queryClient.clear();
                              navigate("/order");
                            }, [1000]);
                          },
                        }
                      );
                    },
                  });
                }}
              >
                <DeleteOutlined style={{ marginRight: "4px" }} />
                移除訂單
              </span>
            ),
          },
        ]
      : [
          {
            key: "copy",
            text: "複製訂單",
            onClick: () => {
              setOpenOrderDrawer(true);
            },
          },
        ];
  const { data: galleriesItems } = useGetCardSeries();
  const path = `${PROTOCOL}://${PRODUCTION_DOMAIN}${location.pathname}${location.search}`;

  const htmlToCanvas = async (_node) => {
    const zip = new JSZip();
    const date = new Date();
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    const filePreName = `${day}${hour}${minute}${second}`;
    for (let i = 0; i < _node.length; i++) {
      await html2canvas(_node[i], {
        useCORS: true,
        scale: 5,
      }).then(async (canvas) => {
        if (_node.length <= 0) return;
        if (_node.length <= 1) {
          Canvas2Image.saveAsPNG(
            canvas,
            canvas.width,
            canvas.height,
            `${filePreName}${orderId}`
          );
          return;
        }
        const addToZip = (fileName) => {
          return new Promise((resolve, reject) => {
            canvas.toBlob((blobObj) => {
              zip.file(fileName, blobObj);
              resolve();
            });
          });
        };
        await addToZip(`${filePreName}${orderId}_${i + 1}.png`);
      });
      if (i === _node.length - 1 && _node.length !== 0) {
        message.success("下載完成");
        setLoading(false);
        if (_node.length >= 2) {
          zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(content, `${NAME_DISPLAY}_${orderId}.zip`);
          });
        }
      }
    }
  };
  const ACTION_BUTTONS = [
    {
      image: "/images/facebook.png",
      name: "Facebook",
      onClick: () => {},
      customBtn: (children) => (
        <FacebookShareButton
          url={path}
          className={actionAreaStyles.actionBtn}
          key="facebook"
        >
          {children}
        </FacebookShareButton>
      ),
    },
    {
      image: "/images/line.png",
      name: "LINE",
      onClick: () => {},
      customBtn: (children) => (
        <LineShareButton
          url={path}
          className={actionAreaStyles.actionBtn}
          key="line"
        >
          {children}
        </LineShareButton>
      ),
    },
    {
      image: "/images/copy_link.png",
      name: "複製連結",
      onClick: () => {
        copyText(path);
      },
    },
    // {
    //   image: "/images/photo_catch.png",
    //   name: "擷取畫面",
    //   onClick: () => {
    //     htmlToCanvas(node);
    //   },
    // },
    // {
    //   image: "/images/more.png",
    //   name: "更多",
    //   onClick: () => {
    //     Dialog.alert({
    //       title: "提示",
    //       content: "近期更新",
    //       closeOnMaskClick: true,
    //     });
    //   },
    // },
  ];

  const findValue = (title, item) => {
    switch (title) {
      case "quota":
        return item.quota;
      case "price":
        return item.amount;
      case "comment":
        return item.comment;
      default:
        return "";
    }
  };

  useEffect(() => {
    if (isSuccess) {
      const content = orderData.content;
      let _items = [];
      content.ptcg_pack_detail.map((item) =>
        _items.push({
          ...item.ptcg_card,
          quota: item.quota,
          amount: item.amount,
          comment: item.comment,
        })
      );
      setItems(_items);
    }
  }, [isSuccess, orderData, navigate]);
  return (
    <div className={styles.orderDetail}>
      <Header
        title={`#${orderId}`}
        extra={
          <Popover.Menu
            actions={actions.map((action) => ({
              ...action,
              icon: null,
            }))}
            placement="bottom-end"
            trigger="click"
            mode="dark"
          >
            <MoreOutlined style={{ fontSize: "16px", cursor: "pointer" }} />
          </Popover.Menu>
        }
        toPage={"/order"}
      />
      <Divider style={{ margin: 0 }} />
      <div className={styles.cardDetailContainer}>
        <CardDetail
          orderData={orderData?.content}
          showTotalArea={true}
          items={items}
          findValue={findValue}
        />
      </div>
      <ActionArea
        items={ACTION_BUTTONS}
        text="產出商品圖"
        onClick={() => {
          history.replaceState({}, "", location.pathname);
          setOpenOrderPhotoDrawer(true);
        }}
      />
      <OrderDrawer
        openOrderDrawer={openOrderDrawer}
        setOpenOrderDrawer={setOpenOrderDrawer}
        items={copyItems}
        setItems={setCopyItems}
        galleriesItems={galleriesItems}
        orderData={orderData}
      />
      <OrderPhotoDrawer
        open={openOrderPhotoDrawer}
        setOpenOrderPhotoDrawer={setOpenOrderPhotoDrawer}
        items={items}
        type={orderData?.content?.type}
        onClick={htmlToCanvas}
        loading={loading}
        setLoading={setLoading}
        orderData={orderData}
        isOpenDrawer={parsed.get("isOpenDrawer") || false}
        setCopyItems={setCopyItems}
        isLoading={isLoading}
        isRefetching={isRefetching}
        actionButtons={ACTION_BUTTONS}
        popupActions={actions}
      />
    </div>
  );
};

export default OrderDetail;
