import { BasicDrawer } from "../../../../component";

const InnerDrawer = ({
  openDrawer,
  setOpenDrawer,
  onCheck,
  drawerItems,
  localSearchValue,
  setLocalSearchValue,
  title,
}) => {
  const onClose = () => {
    setOpenDrawer(false);
  };
  const handleCheck = (checked, item) => {
    const valueSet = (target, targetName) => {
      if (checked) {
        setLocalSearchValue((prev) => {
          target.push(item.value);
          return { ...prev };
        });
      } else {
        setLocalSearchValue((prev) => {
          return {
            ...prev,
            [targetName]: target.filter((_item) => _item !== item.value),
          };
        });
      }
    };
    switch (item.name) {
      case "type":
        valueSet(localSearchValue.card_type, "card_type");
        break;
      case "energy":
        valueSet(localSearchValue.energy_type, "energy_type");
        break;
      case "galleries":
        valueSet(localSearchValue.galleries_id, "galleries_id");
        break;
      case "gameMark":
        valueSet(localSearchValue.game_mark, "game_mark");
        break;
      default:
        break;
    }
  };
  return (
    <BasicDrawer
      open={openDrawer}
      title={title}
      extra={<></>}
      items={drawerItems}
      onClose={onClose}
      rightContainer="checkbox"
      onCheck={handleCheck}
      searchValue={localSearchValue}
    />
  );
};

export default InnerDrawer;
