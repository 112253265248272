import clsx from "clsx";
import styles from "./style.module.scss";

const CustomBtn = ({ onClick, loading = false, text, style }) => {
  return (
    <div className={styles.submitBtn} onClick={onClick} style={style}>
      <div className={clsx(styles.btnText, { [styles.disabled]: loading })}>
        {text}
      </div>
    </div>
  );
};

export default CustomBtn;
