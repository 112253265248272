import { GoogleOAuthProvider } from "@react-oauth/google";
import { ConfigProvider } from "antd";
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import "./App.css";
import FontSetting from "./component/FontSetting";
import { GA_CODE, GOOGLE_CLIENT_ID } from "./config";
import Context from "./context";
import { useLogin } from "./context/LoginContext";
import "./plugins/i18n";
import RouterRender from "./router";
import "./style.scss";

function App() {
  // const navigate = useNavigate();
  const { isLogin } = useLogin();
  const theme = require(`./theme/theme.json`);
  const setTheme = () => {
    const root = document.querySelector(":root");
    const finalTheme = Object.entries(theme);
    finalTheme.map((color) => {
      root.style.setProperty(color[0], color[1]);
      return null;
    });
  };
  const initGTAG = () => {
    if (GA_CODE === "" || GA_CODE === null || GA_CODE === undefined) return;
    TagManager.initialize({
      gtmId: GA_CODE,
    });
  };

  useEffect(() => {
    setTheme();
  }, [theme]);
  useEffect(() => {
    initGTAG();
  }, []);
  useEffect(() => {
    var u = navigator.userAgent,
      ua = navigator.userAgent.toLowerCase();
    let isLineApp = u.indexOf("Line") > -1 ? true : false; // Line 內建瀏覽器
    let isFbApp = u.indexOf("FBAV") > -1 ? true : false; // FB App 內建瀏覽器

    if (
      (isLineApp || isFbApp) &&
      !window.location.href.includes("openExternalBrowser")
    ) {
      var currentUrl = window.location.href;
      var redirectUrl =
        currentUrl.indexOf("?") > -1
          ? currentUrl + "&openExternalBrowser=1"
          : currentUrl + "?openExternalBrowser=1";
      window.location.href = redirectUrl;
    }
  }, []);

  return (
    <div className="App">
      <ConfigProvider
        theme={{
          components: {
            Steps: {
              iconSizeSM: 20,
            },
            InputNumber: {
              activeShadow: 0,
            },
          },
        }}
      >
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <Context>
            <FontSetting />
            <RouterRender />
          </Context>
        </GoogleOAuthProvider>
      </ConfigProvider>
    </div>
  );
}

export default App;
