import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Dialog } from "antd-mobile";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../../../../context/LoginContext";
import theme from "../../../../theme/theme.json";
import styles from "./style.module.scss";

const CardSelectArea = ({
  cardSelect,
  setCardSelect,
  handleRemoveCard,
  setOpenOrderDrawer,
  showSelectCard,
  setShowSelectCard = () => {},
}) => {
  const { isLogin } = useLogin();
  const navigate = useNavigate();
  const isMobile = () => {
    let flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    return flag;
  };
  const leftSideInPc = () => {
    const widthPc = document.body.clientWidth;
    const MAX_WIDTH_IN_PC = theme["--maxWidthInPc"].replace("px", "");
    const result = (widthPc - MAX_WIDTH_IN_PC) / 2;
    if (!isMobile()) return result.toString() + "px";
  };
  const handleClickCart = (e) => {
    e.stopPropagation();
    if (!isLogin) {
      Dialog.show({
        title: "提示",
        content: "您尚未登入",
        closeOnAction: true,
        closeOnMaskClick: true,
        actions: [
          [
            {
              key: "cancel",
              text: "稍後",
            },
            {
              key: "delete",
              text: "登入",
              bold: true,
              onClick: () => {
                navigate("/login");
              },
            },
          ],
        ],
      });
      return;
    }
    if (cardSelect.length <= 0) {
      Dialog.alert({
        title: "提示",
        content: "組合內無項目",
        closeOnMaskClick: true,
      });
      return;
    }
    setOpenOrderDrawer(true);
  };
  return (
    <>
      <div
        className={`${styles.container} ${
          showSelectCard && styles.showSelectCard
        }`}
        onClick={() => {
          if (!showSelectCard) setShowSelectCard(true);
        }}
      >
        <div className={styles.header}>
          <div className={styles.leftContainer}>
            <div
              className={styles.toggleBtn}
              style={{
                backgroundColor: showSelectCard
                  ? "var(--cardSelectToggle-active)"
                  : "var(--cardSelectToggle)",
              }}
              onClick={(e) => setShowSelectCard(!showSelectCard)}
            >
              {showSelectCard ? (
                <MinusOutlined
                  style={{ color: "var(--white)", fontSize: "18px" }}
                />
              ) : (
                <PlusOutlined
                  style={{ color: "var(--white)", fontSize: "18px" }}
                />
              )}
            </div>
            <div className={styles.title}>選取組合({cardSelect.length})</div>
          </div>
          <div className={styles.rightContainer}>
            <Button
              color="primary"
              fill="none"
              size="small"
              disabled={cardSelect.length <= 0}
              onClick={(e) => {
                e.stopPropagation();
                Dialog.show({
                  title: "確認清空選取組合？",
                  closeOnAction: true,
                  closeOnMaskClick: true,
                  actions: [
                    [
                      {
                        key: "cancel",
                        text: "取消",
                      },
                      {
                        key: "confirm",
                        text: "確認",
                        onClick: () => {
                          setCardSelect([]);
                        },
                      },
                    ],
                  ],
                });
              }}
            >
              清空
            </Button>
            <img
              className={styles.cartImg}
              src={`/images/cart.png`}
              alt="cart"
              onClick={(e) => handleClickCart(e)}
            />
          </div>
        </div>
        <div className={styles.cardArea}>
          <div className={styles.cardList}>
            <Droppable droppableId="cardSelect" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={{
                    display: "flex",
                    overflowX: "auto",
                    overflowY: "hidden",
                    height: "120px",
                  }}
                  {...provided.droppableProps}
                >
                  {cardSelect?.map((card, index) => {
                    return (
                      <Draggable
                        key={card.card_id}
                        draggableId={card.card_id}
                        index={index}
                        isDragDisabled={!showSelectCard}
                      >
                        {(provided, snapshot) => {
                          return (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                opacity: snapshot.isDragging ? 0.8 : 1,
                                top: snapshot.isDragging && 50,
                                marginLeft:
                                  snapshot.isDragging && `-${leftSideInPc()}`,
                              }}
                            >
                              <div
                                id={`card_${index}`}
                                className={`${styles.card} ${
                                  showSelectCard && styles.showSelectCard
                                }`}
                                key={card.card_id}
                                onClick={() => {
                                  if (showSelectCard)
                                    handleRemoveCard(card.card_id);
                                }}
                              >
                                <img
                                  className={styles.cardImg}
                                  src={card.image}
                                  alt={card.card_name}
                                />
                              </div>
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardSelectArea;
