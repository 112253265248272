import { CloseOutlined, LeftOutlined } from "@ant-design/icons";
import { Drawer, Form, Steps, message } from "antd";
import { Dialog, DotLoading } from "antd-mobile";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DECK_LIMIT } from "../../config";
import { TYPE } from "../../enumerations/type";
import { usePackOrder } from "../../pages/Home/hooks/index.hooks";
import { checkTotalQuota } from "../../plugins/common";
import { userData } from "../../plugins/localStorage";
import CustomBtn from "../CustomBtn";
import Step0 from "../Step0";
import Step1 from "../Step1";
import Step2 from "../Step2";
import styles from "./style.module.scss";
import "./style.scss";

const OrderDrawer = ({
  openOrderDrawer,
  setOpenOrderDrawer,
  items,
  setItems,
  galleriesItems,
  orderData,
}) => {
  const [step, setStep] = useState(0);
  const [fields, setFields] = useState({});
  const [resOrderData, setResOrderData] = useState("");
  const [totalQuota, setTotalQuota] = useState(0);
  const [currentType, setCurrentType] = useState("");
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { mutate: packOrder, isPending } = usePackOrder();
  const stepItems = [
    {
      title: "編輯",
    },
    {
      title: "確認",
    },
    {
      title: "完成",
    },
  ];
  const onFinish = () => {
    const findCardId = () => {
      const cardId = [];
      Object.entries(fields).map((field) => {
        if (field[0].includes("quota")) {
          cardId.push(field[0].split("_")[1]);
          return cardId;
        }
        return cardId;
      });
      return cardId;
    };
    const findContent = (cardId) => {
      const content = [];
      cardId.map((id) => {
        let cardData = { cardId: id };
        if (fields.type === TYPE["deck"].key) {
          cardData = { ...cardData, comment: "", amount: 0 };
        }
        Object.entries(fields).map((field) => {
          switch (field[0]) {
            case `quota_${id}`:
              return (cardData = { ...cardData, quota: field[1].toString() });
            case `price_${id}`:
              return (cardData = { ...cardData, amount: field[1].toString() });
            case `comment_${id}`:
              return (cardData = { ...cardData, comment: field[1] });
            default:
              return content;
          }
        });
        content.push(cardData);
        return content;
      });
      return content;
    };
    const findTotalAmount = (content) => {
      let totalAmount = 0;
      content.map((item) => {
        totalAmount += item.amount * item.quota;
        return totalAmount;
      });
      return totalAmount.toString();
    };
    const cardId = findCardId();
    const content = findContent(cardId);
    const totalAmount = findTotalAmount(content);
    packOrder(
      {
        userId: userData().userId,
        type: TYPE[fields.type].key,
        pack_amount: totalAmount,
        pack_comment: fields.comment,
        content,
      },
      {
        onSuccess: (data) => {
          // setStep(2);
          // setResOrderData(data);
          message.success("訂單已成立");
          navigate(`/order/${data.packId}?isOpenDrawer=true`);
        },
        onError: (error) => {
          console.log("pack order", error);
        },
      }
    );
  };
  const handleClickNext = () => {
    let dialogContent = "";
    const type = form?.getFieldsValue().type;
    if (step === 0 && type === TYPE["deck"].key && totalQuota !== DECK_LIMIT) {
      if (totalQuota < DECK_LIMIT) {
        dialogContent = "牌組卡牌數量少於60張卡片";
      } else if (totalQuota > DECK_LIMIT) {
        dialogContent = "牌組卡牌數量超過60張卡片";
      }
      Dialog.alert({
        title: "提示",
        content: dialogContent,
        closeOnMaskClick: true,
      });
      return;
    }
    if (step === 1) {
      if (!isPending) onFinish();
      return;
    }
    setStep((prev) => prev + 1);
    setFields(form?.getFieldsValue());
  };
  const handleClose = () => {
    setOpenOrderDrawer(false);
    form?.resetFields();
    setStep(0);
  };
  const updateTotalQuota = () => {
    const _fields = form?.getFieldsValue();
    setTotalQuota(checkTotalQuota(_fields));
  };
  useEffect(() => {
    if (openOrderDrawer) {
      const fields = form?.getFieldsValue();
      setCurrentType(fields.type);
      updateTotalQuota();
    }
  }, [openOrderDrawer, form]);
  return (
    <Drawer
      className="order-drawer"
      title="成立訂單"
      width="100%"
      onClose={() => setStep((prev) => prev - 1)}
      open={openOrderDrawer}
      extra={<CloseOutlined onClick={handleClose} />}
      closeIcon={
        step === 1 ? (
          <LeftOutlined style={{ color: "var(--text5)", fontSize: "16px" }} />
        ) : (
          <div style={{ width: "16px" }}></div>
        )
      }
    >
      <div className={styles.container}>
        <Steps
          current={step}
          size="small"
          labelPlacement="vertical"
          items={stepItems}
          responsive={false}
        />
        <Form
          form={form}
          name="order"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          {step === 0 ? (
            <Step0
              items={items}
              setItems={setItems}
              galleriesItems={galleriesItems}
              form={form}
              orderData={orderData?.content}
              totalQuota={totalQuota}
              updateTotalQuota={updateTotalQuota}
              currentType={currentType}
              setCurrentType={setCurrentType}
            />
          ) : step === 1 ? (
            <Step1 items={items} fieldsValue={fields} />
          ) : (
            <Step2 resOrderData={resOrderData} />
          )}
        </Form>
        {step === 0 || step === 1 ? (
          <CustomBtn
            onClick={handleClickNext}
            loading={isPending}
            style={{ backgroundColor: "var(--white)", marginTop: "-52px" }}
            text={
              step === 0 ? (
                "下一步"
              ) : (
                <span>
                  確認，成立訂單
                  {isPending && <DotLoading color="var(--white)" />}
                </span>
              )
            }
          />
        ) : (
          ""
        )}
      </div>
    </Drawer>
  );
};

export default OrderDrawer;
