import { Dialog } from "antd-mobile";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../../../..";
import { useLogin } from "../../../../context/LoginContext";
import { userData } from "../../../../plugins/localStorage";
import InfoDrawer from "../InfoDrawer";
import InformationDrawer from "../InformationDrawer";
import styles from "./style.module.scss";

const Header = () => {
  const [openInfoDrawer, setOpenInfoDrawer] = useState(false);
  const [openInformationDrawer, setOpenInformationDrawer] = useState(false);
  const { isLogin } = useLogin();
  const navigate = useNavigate();
  const handleClickItem = (item) => {
    switch (item.id) {
      case "information":
        // setOpenInformationDrawer(true);
        Dialog.alert({
          title: "提示",
          content: "近期更新",
          closeOnMaskClick: true,
        });
        break;
      case "order":
        navigate("/order");
        break;
      default:
        break;
    }
  };
  return (
    <div className={styles.header}>
      <div className={styles.leftContainer}>
        {/* <img
          src={hamburger}
          alt="logo"
          className={styles.header__left__hamburger}
        /> */}
        <img
          src={`/images/logo.png`}
          alt="logo"
          className={styles.logo}
          onClick={() => navigate("/")}
        />
      </div>
      {isLogin ? (
        <img
          src={userData().avatar ? userData().avatar : `/images/avatar.png`}
          alt="avatar"
          className={styles.personPhoto}
          onClick={() => setOpenInfoDrawer(true)}
        />
      ) : (
        <div
          className={styles.loginBtn}
          onClick={() => {
            queryClient.clear();
            navigate("/login");
          }}
        >
          登入
        </div>
      )}
      <InfoDrawer
        open={openInfoDrawer}
        onClose={() => setOpenInfoDrawer(false)}
        onClickItem={handleClickItem}
      />
      <InformationDrawer
        open={openInformationDrawer}
        onClose={() => setOpenInformationDrawer(false)}
      />
    </div>
  );
};

export default Header;
