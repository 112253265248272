import localStorageKey from "../enumerations/localStorageKey";

export const userData = () => {
  const data = localStorage.getItem(localStorageKey.USER_DATA)
    ? JSON.parse(localStorage.getItem(localStorageKey.USER_DATA))
    : {};
  Object.entries(data).map((item) => {
    if (item[1] === null) {
      data[item[0]] = "";
      return item;
    } else {
      return item;
    }
  });
  return data;
};

export const remember =
  localStorage.getItem(localStorageKey.REMEMBER) === "true" ? true : false;
