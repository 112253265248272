import React, { useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { OrderDrawer } from "../../component/index.js";
import localStorageKey from "../../enumerations/localStorageKey.js";
import { useGetCardSeries } from "../../hooks/cardSeries/cardSeries.hooks.js";
import { CardSelectArea, FilterDrawer, Header, Search } from "./component";
import CardList from "./component/CardList/index.jsx";
import styles from "./style.module.scss";

const Home = () => {
  const [searchValue, setSearchValue] = useState({
    card_name: "",
    card_type: [],
    energy_type: [],
    galleries_id: [],
    game_mark: [],
  });
  const [localSearchValue, setLocalSearchValue] = useState(searchValue);
  const [showSelectCard, setShowSelectCard] = useState(false);
  const localStorageCardSelect = localStorage.getItem(
    localStorageKey.CARD_SELECT
  )
    ? JSON.parse(localStorage.getItem(localStorageKey.CARD_SELECT))
    : [];
  const [cardSelect, setCardSelect] = useState(localStorageCardSelect);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [openOrderDrawer, setOpenOrderDrawer] = useState(false);
  const [openHotRender, setOpenHotRender] = useState(false);
  const { data: galleriesItems } = useGetCardSeries();
  const handleRemoveCard = (id) => {
    return setCardSelect((prev) => prev.filter((card) => card.card_id !== id));
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newList = reorder(
      cardSelect,
      result.source.index,
      result.destination.index
    );
    setCardSelect([...newList]);
  };

  useEffect(() => {
    if (cardSelect) {
      localStorage.setItem(
        localStorageKey.CARD_SELECT,
        JSON.stringify(cardSelect)
      );
    }
  }, [cardSelect]);
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={styles.home}>
        <Header />
        <Search
          setSearchValue={setSearchValue}
          localSearchValue={localSearchValue}
          setLocalSearchValue={setLocalSearchValue}
          openHotRender={openHotRender}
          setOpenHotRender={setOpenHotRender}
          setOpenFilterDrawer={setOpenFilterDrawer}
        />
        <CardList
          searchValue={searchValue}
          cardSelect={cardSelect}
          setCardSelect={setCardSelect}
          handleRemoveCard={handleRemoveCard}
          openFilterDrawer={openFilterDrawer}
          openHotRender={openHotRender}
        />
        <CardSelectArea
          cardSelect={cardSelect}
          setCardSelect={setCardSelect}
          handleRemoveCard={handleRemoveCard}
          setOpenOrderDrawer={setOpenOrderDrawer}
          showSelectCard={showSelectCard}
          setShowSelectCard={setShowSelectCard}
        />
        <FilterDrawer
          openFilterDrawer={openFilterDrawer}
          setOpenFilterDrawer={setOpenFilterDrawer}
          setSearchValue={setSearchValue}
          localSearchValue={localSearchValue}
          setLocalSearchValue={setLocalSearchValue}
          galleriesItems={galleriesItems}
        />
        <OrderDrawer
          openOrderDrawer={openOrderDrawer}
          setOpenOrderDrawer={setOpenOrderDrawer}
          items={cardSelect}
          setItems={setCardSelect}
          galleriesItems={galleriesItems}
        />
      </div>
    </DragDropContext>
  );
};

export default Home;
