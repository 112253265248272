import { Divider } from "antd-mobile";
import { TYPE } from "../../enumerations/type";
import { findTotalQuota, moneyFormat } from "../../plugins/common";
import Magnifier from "../Magnifier";
import styles from "./style.module.scss";

const CardDetail = ({
  orderData,
  items,
  findValue = () => {},
  showTotalArea,
}) => {
  return (
    <>
      <div className={styles.describe}>
        <img
          className={styles.buySellImg}
          src={TYPE[orderData?.type]?.img}
          alt={orderData?.type}
        />
        <div className={styles.describeText}>
          {orderData?.pack_comment || orderData?.comment || "無附加說明"}
        </div>
      </div>
      <div className={styles.cardContainer}>
        {showTotalArea && (
          <div className={styles.totalArea}>
            <div className={styles.totalQuota}>
              <div className={styles.totalQuotaTitle}>總數量</div>
              <div className={styles.totalQuotaValue}>
                {findTotalQuota(orderData)}
              </div>
            </div>
            <Divider style={{ margin: "8px" }} />
            <div className={styles.totalAmount}>
              <div className={styles.totalAmountTitle}>總金額</div>
              <div className={styles.totalAmountValue}>
                {moneyFormat(orderData?.pack_amount)}
              </div>
            </div>
          </div>
        )}
        <div className={styles.cardDetail}>
          {items &&
            items.map((item, index) => {
              return (
                <div className={styles.item} key={index}>
                  <div className={styles.leftContainer}>
                    <Magnifier
                      children={
                        <img
                          className={styles.image}
                          src={item.image}
                          alt={item.card_name}
                        />
                      }
                      card={item}
                    ></Magnifier>
                    <div className={styles.data}>
                      <div className={styles.dataTitle}>
                        數量
                        <span className={styles.dataValue}>
                          {findValue("quota", item)}
                        </span>
                      </div>
                      <div className={styles.dataTitle}>
                        單價
                        <span className={styles.dataValue}>
                          {findValue("price", item) === 0 ||
                          !findValue("price", item)
                            ? "-"
                            : moneyFormat(findValue("price", item))}
                        </span>
                      </div>
                      <div className={styles.dataDescribe}>
                        <div className={styles.describeTitle}>備註</div>
                        <div
                          className={`${styles.describeValue} ${styles.orange}`}
                        >
                          {findValue("comment", item) || "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default CardDetail;
