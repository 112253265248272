import {
  requestCardHot,
  requestCardSearch,
  requestCardSearchNewest,
  requestPackOrder,
} from "../../api";

export const apiGetCardSearch = ({ params, page }) =>
  requestCardSearch("get", `/api/card/search`, { ...params, page });

export const apiGetCardSearchNewest = ({ params, page }) =>
  requestCardSearchNewest("get", `/api/card/newest`, { ...params, page });

export const apiGetCardHot = (params) =>
  requestCardHot("get", `/api/card/hot`, params);

export const apiRequestPackOrder = (params) =>
  requestPackOrder("post", `/api/order/pack`, params, true);
