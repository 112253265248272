import { message } from "antd";

export const copyText = (value) => {
  let oInput = document.createElement("input");
  oInput.value = value;
  document.body.appendChild(oInput);
  oInput.select(); // 选择对象
  document.execCommand("Copy"); // 执行浏览器复制命令
  message.success("複製成功");
  oInput.remove();
};

export const findTotalQuota = (order) => {
  let totalQuota = 0;
  order?.ptcg_pack_detail?.map(
    (perCard) => (totalQuota += Number(perCard.quota))
  );
  return totalQuota;
};

export const checkTotalQuota = (fields) => {
  let totalQuota = 0;
  Object.entries(fields).map((item) => {
    if (item[0].includes("quota")) totalQuota += item[1];
    return item;
  });
  return totalQuota;
};

export const moneyFormat = (money) => {
  if (!money && money !== 0) return;
  if (typeof money === "number") {
    money = money.toString();
  }
  let moneyArr = money.split("");
  let quotient = Math.floor(moneyArr.length / 3);
  let mod = Math.floor(moneyArr.length % 3);
  if (moneyArr.length >= 4) {
    if (mod > 0) {
      for (let i = 0; i < quotient; i++) {
        moneyArr.splice(mod + 4 * i, 0, ",");
      }
    } else {
      for (let i = 0; i < quotient - 1; i++) {
        moneyArr.splice(3 + 4 * i, 0, ",");
      }
    }
  }
  return moneyArr.join("");
};
