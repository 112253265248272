import { CustomBtn } from "../../../../component";
import styles from "./style.module.scss";

const ActionArea = ({ items = [], text, onClick, loading }) => {
  return (
    <div className={styles.actionArea}>
      <div className={styles.container}>
        {items.length > 0 &&
          items.map((item, index) => {
            if (item.customBtn) {
              return item.customBtn(
                <>
                  <img
                    className={styles.actionBtnImg}
                    src={item.image}
                    alt={item.name}
                  />
                  <span className={styles.actionBtnName}>{item.name}</span>
                </>
              );
            } else {
              return (
                <div
                  key={index}
                  className={styles.actionBtn}
                  onClick={() => item.onClick()}
                >
                  <img
                    className={styles.actionBtnImg}
                    src={item.image}
                    alt={item.name}
                  />
                  <span className={styles.actionBtnName}>{item.name}</span>
                </div>
              );
            }
          })}
      </div>
      <CustomBtn onClick={onClick} text={text} loading={loading} />
    </div>
  );
};

export default ActionArea;
