import { usePreviewCard } from "../../context/PreviewCardContext";
import styles from "./style.module.scss";

const Magnifier = ({ children, card }) => {
  const { setShowPrev, setCardSrc } = usePreviewCard();
  const handleClickPreview = (e, src) => {
    e.stopPropagation();
    setShowPrev(true);
    setCardSrc(src);
  };
  return (
    <>
      <div style={{ position: "relative" }}>
        {children}
        <img
          className={styles.magnifier}
          src={`/images/magnifier.png`}
          alt="magnifier"
          onClick={(e) => handleClickPreview(e, card.image)}
        />
      </div>
    </>
  );
};

export default Magnifier;
