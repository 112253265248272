import {
  CaretRightOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Form, Input, InputNumber, Radio } from "antd";
import { Dialog, Divider, SwipeAction } from "antd-mobile";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { DECK_LIMIT } from "../../config";
import { TYPE } from "../../enumerations/type";
import {
  energyItems,
  energyList,
} from "../../pages/Home/enumeration/energyItemsEnum";
import { gameMarkItems } from "../../pages/Home/enumeration/gameMarkItemsEnum";
import { typeItems } from "../../pages/Home/enumeration/typeItemsEnum";
import Magnifier from "../Magnifier";
import styles from "./style.module.scss";
import "./style.scss";

const Step0 = ({
  items,
  setItems,
  galleriesItems,
  form,
  orderData,
  totalQuota,
  updateTotalQuota,
  currentType,
  setCurrentType,
}) => {
  const rightActions = [
    // {
    //   key: "orderPhoto",
    //   text: "商品圖",
    //   color: "var(--warning6)",
    // },
    {
      key: "delete",
      text: "刪除",
      color: items.length >= 2 ? "var(--danger6)" : "var(--fill4)",
    },
  ];
  const handleRemoveItem = (item) => {
    Dialog.confirm({
      title: `確認移除項目 ${item.card_name}?`,
      closeOnAction: true,
      closeOnMaskClick: true,
      onConfirm: async () => {
        await setItems((prev) =>
          prev.filter((_item) => _item.card_id !== item.card_id)
        );
        updateTotalQuota();
      },
    });
  };
  const handleClickAction = (action, e, item) => {
    switch (action.key) {
      case "delete":
        if (items.length <= 1) {
          Dialog.alert({
            title: "至少保留一張卡片",
            closeOnAction: true,
            closeOnMaskClick: true,
          });
          return;
        }
        handleRemoveItem(item);
        break;
      default:
        break;
    }
  };

  const findEnum = (targetName, targetEnum, type = "") => {
    if (!galleriesItems) return;
    if (type === "energy") {
      const target = energyList.filter((item) => item.name === targetName);
      return target[0]?.img;
    } else {
      const target = targetEnum.filter((item) => item.value === targetName);
      return target[0]?.displayName;
    }
  };
  const handleClickPlus = (item) => {
    const targetName = "quota_" + item.card_id;
    const currentValue = form?.getFieldValue(targetName);
    form?.setFieldValue(targetName, currentValue + 1);
    updateTotalQuota();
  };
  const handleClickMinus = (item) => {
    const targetName = "quota_" + item.card_id;
    const currentValue = form?.getFieldValue(targetName);
    if (currentValue <= 1) return;
    form?.setFieldValue(targetName, currentValue - 1);
    updateTotalQuota();
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newList = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems([...newList]);
  };

  const renderBuySellContainer = () => {
    return (
      <Form.Item
        name="type"
        initialValue={orderData ? TYPE[orderData.type]?.key : "buy"}
      >
        <Radio.Group onChange={(e) => setCurrentType(e.target.value)}>
          <Radio value={"buy"}>{TYPE["buy"].text}</Radio>
          <Radio value={"sell"}>{TYPE["sell"].text}</Radio>
          <Radio value={"deck"}>
            {TYPE["deck"].text}
            <span
              style={{
                color:
                  totalQuota !== DECK_LIMIT
                    ? "var(--danger6)"
                    : "var(--success6",
                opacity: currentType === TYPE["deck"]?.key ? 1 : 0,
              }}
            >
              ({totalQuota}/{DECK_LIMIT})
            </span>
          </Radio>
        </Radio.Group>
      </Form.Item>
    );
  };
  const renderDescribeContainer = () => {
    return (
      <Form.Item
        name="comment"
        initialValue={orderData ? orderData.pack_comment : ""}
      >
        <Input showCount maxLength={30} placeholder="添加訂單附加說明 (選填)" />
      </Form.Item>
    );
  };
  const renderItemContainer = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(droppableProvided) => (
            <div ref={droppableProvided.innerRef}>
              {items &&
                items.map((item, index) => (
                  <Draggable
                    key={item.card_id}
                    draggableId={item.card_id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          opacity: snapshot.isDragging ? 0.8 : 1,
                        }}
                      >
                        <SwipeAction
                          key={index}
                          //TODO leftActions={leftActions}
                          rightActions={rightActions}
                          onAction={(action, e) => {
                            handleClickAction(action, e, item);
                          }}
                        >
                          <div key={index}>
                            <div className={styles.item}>
                              <div className={styles.leftContainer}>
                                <Magnifier
                                  children={
                                    <img
                                      className={styles.image}
                                      src={item.image}
                                      alt={item.card_name}
                                    />
                                  }
                                  card={item}
                                ></Magnifier>
                                <div className={styles.data}>
                                  <div className={styles.dataName}>
                                    {item.card_name}
                                  </div>
                                  <div className={styles.dataFilter}>
                                    {item.energy_type && (
                                      <img
                                        className={styles.energy}
                                        src={findEnum(
                                          item.energy_type,
                                          energyItems,
                                          "energy"
                                        )}
                                        alt={item.energy_type}
                                      />
                                    )}
                                    <div className={styles.mark}>
                                      {findEnum(item.game_mark, gameMarkItems)}
                                    </div>
                                    <div className={styles.galleries}>
                                      {findEnum(
                                        item.galleries_id,
                                        galleriesItems
                                      )}
                                    </div>
                                    <CaretRightOutlined
                                      style={{
                                        fontSize: "8px",
                                        color: "var(--text4)",
                                        margin: "0 4px",
                                      }}
                                    />
                                    <div className={styles.type}>
                                      {findEnum(item.card_type, typeItems)}
                                    </div>
                                  </div>
                                  {currentType !== TYPE["deck"].key && (
                                    <>
                                      <div
                                        className={`${styles.dataDescribe} data-describe`}
                                      >
                                        <Form.Item
                                          name={`comment_${item.card_id}`}
                                          initialValue={
                                            item.comment ? item.comment : ""
                                          }
                                        >
                                          <Input
                                            placeholder="添加備註"
                                            maxLength={5}
                                            showCount
                                          />
                                        </Form.Item>
                                      </div>
                                      <div
                                        className={`${styles.dataPrice} data-price`}
                                      >
                                        <div className={styles.currency}>$</div>
                                        <Form.Item
                                          name={`price_${item.card_id}`}
                                          initialValue={
                                            item.amount
                                              ? Number(item.amount)
                                              : 0
                                          }
                                        >
                                          <InputNumber
                                            formatter={(value) =>
                                              !value ? 0 : value
                                            }
                                            parser={(value) => Math.abs(value)}
                                          />
                                        </Form.Item>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className={styles.rightContainer}>
                                <div
                                  className={styles.minus}
                                  onClick={() => handleClickMinus(item)}
                                >
                                  <MinusOutlined style={{ fontSize: "16px" }} />
                                </div>
                                <div className={`${styles.quota} quota`}>
                                  <Form.Item
                                    name={`quota_${item.card_id}`}
                                    initialValue={
                                      item.quota ? Number(item.quota) : 1
                                    }
                                  >
                                    <InputNumber
                                      formatter={(value) =>
                                        !value ? 1 : value
                                      }
                                      parser={(value) => Math.abs(value)}
                                      onChange={() => updateTotalQuota()}
                                    />
                                  </Form.Item>
                                </div>
                                <div
                                  className={styles.plus}
                                  onClick={() => handleClickPlus(item)}
                                >
                                  <PlusOutlined style={{ fontSize: "16px" }} />
                                </div>
                              </div>
                              {/* {items.length >= 2 && (
                        <CloseOutlined
                          className={styles.closeBtn}
                          onClick={() => handleRemoveItem(item)}
                        />
                      )} */}
                            </div>
                            <Divider style={{ margin: 0 }} />
                          </div>
                        </SwipeAction>
                      </div>
                    )}
                  </Draggable>
                ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  return (
    <>
      <div className={`${styles.buyOrSellContainer} buy-sell-container`}>
        {renderBuySellContainer()}
      </div>
      <div className={`${styles.describeContainer} describe-container`}>
        {renderDescribeContainer()}
      </div>
      <div className={styles.itemContainer}>{renderItemContainer()}</div>
    </>
  );
};

export default Step0;
