const PROTOCOL = "https";
const PRODUCTION_DOMAIN = "cardbuy.com.tw";
const API_DOMAIN = "admin.cardbuy.com.tw";
const NAME_DISPLAY = "Cardbuy";
const GOOGLE_CLIENT_ID =
  "616836316603-iink3pli69kksblcs6qlv4pdc7kpjuve.apps.googleusercontent.com";
const VERSION = "1.0.2";
const DECK_LIMIT = 60;
const GA_CODE = "G-CBV8B3MM4W";

export {
  API_DOMAIN,
  DECK_LIMIT,
  GA_CODE,
  GOOGLE_CLIENT_ID,
  NAME_DISPLAY,
  PRODUCTION_DOMAIN,
  PROTOCOL,
  VERSION,
};
