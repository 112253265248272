import { CloseOutlined } from "@ant-design/icons";
import { LoginForm } from "@ant-design/pro-components";
import { useNavigate } from "react-router-dom";
import styles from "./style.module.scss";

const LoginSignUp = ({ form, title, onFinish, actions, children }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <div className={styles.close} onClick={() => navigate("/")}>
          <CloseOutlined style={{ color: "var(--white)", fontSize: "16px" }} />
        </div>
        <img
          className={styles.logo}
          src={`/images/login_logo.png`}
          alt="logo"
        />
      </div>
      <div className={`${styles.bottomContainer} bottom-container`}>
        <LoginForm
          form={form}
          title={title}
          subTitle=" "
          submitter={{
            searchConfig: {
              submitText: title,
            },
            submitButtonProps: {
              style: {
                width: "100%",
              },
            },
          }}
          onFinish={onFinish}
          actions={actions}
        >
          {children}
        </LoginForm>
      </div>
    </div>
  );
};

export default LoginSignUp;
