import localStorageKey from "../enumerations/localStorageKey";

export const logout = () => {
  window.localStorage.removeItem(localStorageKey.TOKEN);
  window.localStorage.removeItem(localStorageKey.USER_DATA);
  // window.localStorage.removeItem(localStorageKey.CARD_SELECT);
  if (window.location.pathname !== "/login") {
    setTimeout(() => {
      window.location.href = "/login";
    }, 1000);
  }
};
