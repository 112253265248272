import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../..";
import { useLogin } from "../../context/LoginContext";
import styles from "./style.module.scss";
import "./style.scss";

const Header = ({
  toPage = -1,
  title,
  extra,
  drawer,
  setOpenOrderPhotoDrawer,
  isOpenDrawer,
}) => {
  const { isLogin } = useLogin();
  const navigate = useNavigate();

  return (
    <div className={`${styles.header} header`}>
      <div className={styles.leftContainer}>
        {isLogin && (
          <LeftOutlined
            style={{ fontSize: "16px" }}
            onClick={() => {
              if (drawer) {
                if (isOpenDrawer) {
                  navigate(toPage);
                  return;
                } else {
                  setOpenOrderPhotoDrawer(false);
                  return;
                }
              }
              queryClient.clear();
              navigate(toPage);
            }}
          />
        )}
      </div>
      <div className={styles.centerContainer}>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.rightContainer}>{isLogin && extra}</div>
    </div>
  );
};

export default Header;
