export const queryKey = {
  CARD: "card",
  CARD_NEWEST: "cardNewest",
  CARD_SERIES: "cardSeries",
  CARD_HOT: "cardHot",
  SIGN_UP: "signUp",
  QUERY_ORDER: "queryOrder",
  ORDER_LIST: "orderList",
  DELETE_ORDER: "deleteOrder",
};
