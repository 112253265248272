import { message } from "antd";
import {
  Dialog,
  DotLoading,
  ErrorBlock,
  List,
  PullToRefresh,
  SwipeAction,
} from "antd-mobile";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { statusRecord } from "../../../../enumerations/statusRecord";
import { TYPE } from "../../../../enumerations/type";
import { findTotalQuota } from "../../../../plugins/common";
import { useDeleteOrder, useGetOrderList } from "../../hooks/index.hooks";
import styles from "./style.module.scss";
import "./style.scss";

const OrderList = () => {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const { data, isSuccess, isLoading, refetch, isRefetching } =
    useGetOrderList();
  const { mutate: deleteOrder } = useDeleteOrder();
  const leftActions = [
    {
      key: "pin",
      text: "置頂",
      color: "var(--warning6)",
    },
  ];
  const rightActions = [
    // {
    //   key: "orderPhoto",
    //   text: "商品圖",
    //   color: "var(--warning6)",
    // },
    {
      key: "delete",
      text: "刪除",
      color: "var(--danger6)",
    },
  ];

  const handleClickAction = async (action, e, item) => {
    switch (action.key) {
      case "delete":
        Dialog.confirm({
          title: `確認移除訂單 #${item.pack_id}?`,
          closeOnAction: true,
          closeOnMaskClick: true,
          onConfirm: () => {
            deleteOrder(
              { orderId: item.pack_id },
              {
                onSuccess: (data) => {
                  message.success("刪除成功");
                  refetch();
                },
              }
            );
          },
        });
        break;
      case "pin":
        break;
      case "orderPhoto":
        navigate(`/order/${item.pack_id}?isOpenDrawer=true`);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (isSuccess) {
      const content = data.content;
      if (!content) {
        setItems([]);
        return;
      }
      let _items = [];
      content.map((perOrder) => {
        _items.push({
          pack_id: perOrder.pack_id,
          type: perOrder.type,
          quota: findTotalQuota(perOrder),
          images: perOrder.ptcg_pack_detail.map(
            (perCard) => perCard.ptcg_card.image
          ),
        });
        return _items;
      });
      setItems(_items);
    }
  }, [isSuccess, data, navigate]);

  return (
    <div className="order-list">
      {isLoading || isRefetching ? (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <DotLoading />
          <span>加載中</span>
        </div>
      ) : items.length === 0 ? (
        <ErrorBlock status="empty" title="目前尚未有訂單" description={null} />
      ) : (
        <PullToRefresh
          onRefresh={async () => {
            await refetch();
          }}
          renderText={(status) => {
            return <div>{statusRecord[status]}</div>;
          }}
        >
          <List>
            {items.map((item, index) => {
              return (
                <SwipeAction
                  key={index}
                  //TODO leftActions={leftActions}
                  rightActions={rightActions}
                  onAction={(action, e) => {
                    handleClickAction(action, e, item);
                  }}
                >
                  <List.Item
                    onClick={() =>
                      navigate(`/order/${item.pack_id}?isOpenDrawer=true`)
                    }
                    key={index}
                  >
                    <div className={styles.order}>
                      <div className={styles.cardList}>
                        {item.images?.map((img, index) => {
                          if (index < 3)
                            return (
                              <div className={styles.card} key={index}>
                                <img
                                  className={styles.cardImg}
                                  src={img}
                                  alt={img}
                                />
                              </div>
                            );
                        })}
                      </div>
                      <div className={styles.data}>
                        <div className={styles.orderNumber}>
                          #{item.pack_id}
                        </div>
                        <div className={styles.buySell}>
                          <img
                            className={styles.buySellImg}
                            src={TYPE[item?.type]?.img}
                            alt={data?.content?.type}
                          />
                        </div>
                        <div className={styles.cardQuota}>
                          <img
                            className={styles.quotaImg}
                            src={`/images/card_quota.png`}
                            alt="quotaImg"
                          />
                          <div className={styles.quotaValue}>{item.quota}</div>
                        </div>
                      </div>
                    </div>
                  </List.Item>
                </SwipeAction>
              );
            })}
          </List>
        </PullToRefresh>
      )}
    </div>
  );
};

export default OrderList;
