export const gameMarkItems = [
  { displayName: "A", value: "A", name: "gameMark" },
  { displayName: "B", value: "B", name: "gameMark" },
  { displayName: "C", value: "C", name: "gameMark" },
  { displayName: "D", value: "D", name: "gameMark" },
  { displayName: "E", value: "E", name: "gameMark" },
  { displayName: "F", value: "F", name: "gameMark" },
  { displayName: "G", value: "G", name: "gameMark" },
  { displayName: "H", value: "H", name: "gameMark" },
];
