import React from "react";
import { LoginContextProvider } from "./LoginContext";
import { PreviewCardContextProvider } from "./PreviewCardContext";

const ContextList = [
  { name: "PreviewCardContext", context: PreviewCardContextProvider },
];

function Context(props) {
  let output = props.children;
  ContextList.forEach((contextInfo) => {
    output = <contextInfo.context>{output}</contextInfo.context>;
  });
  return output;
}
export { LoginContextProvider };

export default Context;
