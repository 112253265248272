import { Header } from "../../component";
import OrderList from "./component/OrderList";
import styles from "./style.module.scss";

const Order = () => {
  return (
    <div className={styles.order}>
      <Header toPage="/" title="訂單總覽" extra={<></>} />
      <OrderList />
    </div>
  );
};

export default Order;
