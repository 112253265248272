export const TYPE = {
  buy: {
    key: "buy",
    text: "購買",
    bgColor1: "var(--orderPhotoBuyBg1)",
    bgColor2: "var(--orderPhotoBuyBg2)",
    headerTextColor1: "var(--orderPhotoHeaderBuyText1)",
    headerTextColor2: "var(--orderPhotoHeaderBuyText2)",
    img: "/images/buy.png",
  },
  sell: {
    key: "sell",
    text: "出售",
    bgColor1: "var(--orderPhotoSellBg1)",
    bgColor2: "var(--orderPhotoSellBg2)",
    headerTextColor1: "var(--orderPhotoHeaderSellText1)",
    headerTextColor2: "var(--orderPhotoHeaderSellText2)",
    img: "/images/sell.png",
  },
  deck: {
    key: "deck",
    text: "牌組",
    bgColor1: "var(--orderPhotoDeckBg1)",
    bgColor2: "var(--orderPhotoDeckBg2)",
    headerTextColor1: "var(--orderPhotoHeaderDeckText1)",
    headerTextColor2: "var(--orderPhotoHeaderDeckText2)",
    img: "/images/deck.png",
  },
};
