import {
  Dialog,
  DotLoading,
  ErrorBlock,
  Footer,
  PullToRefresh,
} from "antd-mobile";
import { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-photo-view/dist/react-photo-view.css";
import { queryClient } from "../../../..";
import { Magnifier } from "../../../../component";
import { queryKey } from "../../../../enumerations/queryKey";
import { statusRecord } from "../../../../enumerations/statusRecord";
import { useLazyLoad } from "../../../../hooks/lazyLoad/lazyLoad.hooks";
import {
  useGetCardSearch,
  useGetCardSearchNewest,
} from "../../hooks/index.hooks";
import styles from "./style.module.scss";
import "./style.scss";

const CardList = ({
  searchValue,
  cardSelect,
  setCardSelect,
  handleRemoveCard,
  openFilterDrawer,
  openHotRender,
}) => {
  const [cardSearchProps, setCardSearchProps] = useState({});
  const [params, setParams] = useState(null);
  const observer = useRef(null);

  const isExistsSearchValue = (searchValue) => {
    let result = false;
    Object.values(searchValue).map((value) => {
      if (value.length > 0) result = true;
      return value;
    });
    return result;
  };

  const {
    data: cardSearch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch: refetchCardSearch,
    isLoading: isCardSearchLoading,
    isError: isCardSearchError,
    isRefetching,
  } = useGetCardSearch(
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages?.length < lastPage?.last_page) {
          return pages?.length + 1;
        } else {
          return undefined;
        }
      },
      enabled:
        !openFilterDrawer &&
        !openHotRender &&
        !!params &&
        isExistsSearchValue(searchValue),
    },
    params
  );
  const {
    data: cardSearchNewest,
    hasNextPage: hasNextPageNewest,
    fetchNextPage: fetchNextPageNewest,
    isFetchingNextPage: isFetchingNextPageNewest,
    refetch: refetchCardSearchNewest,
    isLoading: isCardSearchNewestLoading,
    isError: isCardSearchNewestError,
    isRefetching: isCardSearchNewestRefetching,
  } = useGetCardSearchNewest({
    getNextPageParam: (lastPage, pages) => {
      if (pages?.length < lastPage?.last_page) {
        return pages?.length + 1;
      } else {
        return undefined;
      }
    },
    enabled:
      !openFilterDrawer &&
      !openHotRender &&
      !!params &&
      !isExistsSearchValue(searchValue),
  });
  const lazyLoad = useLazyLoad({
    triggerRef: observer,
    hasNextPage: cardSearchProps.hasNextPage,
    fetchNextPage: cardSearchProps.fetchNextPage,
    isFetching: cardSearchProps.isFetchingNextPage,
  });

  const handleAddCard = (card) => {
    if (isSelected(card.card_id)) return;
    setCardSelect((prev) => [...prev, card]);
  };
  const isSelected = (id) => {
    let selectedId = [];
    cardSelect.map((card) => selectedId.push(card.card_id));
    return selectedId.includes(id);
  };

  useEffect(() => {
    if (isExistsSearchValue(searchValue)) {
      setCardSearchProps({
        data: cardSearch,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        refetch: refetchCardSearch,
        isLoading: isCardSearchLoading,
        isError: isCardSearchError,
        isRefetching,
      });
    } else {
      setCardSearchProps({
        data: cardSearchNewest,
        hasNextPage: hasNextPageNewest,
        fetchNextPage: fetchNextPageNewest,
        isFetchingNextPage: isFetchingNextPageNewest,
        refetch: refetchCardSearchNewest,
        isLoading: isCardSearchNewestLoading,
        isError: isCardSearchNewestError,
        isRefetching: isCardSearchNewestRefetching,
      });
    }
    setParams({
      card_name: searchValue.card_name,
      card_type: searchValue.card_type.join(","),
      energy_type: searchValue.energy_type.join(","),
      galleries_id: searchValue.galleries_id.join(","),
      game_mark: searchValue.game_mark.join(","),
    });
  }, [searchValue, cardSearch, cardSearchNewest]);
  return (
    <>
      <div className={styles.cardList}>
        {/* <div className={styles.filterContainer}>
          <span className={styles.filter}>
            清空
            <CloseCircleOutlined />
          </span>
          <span className={styles.filter}>寶可夢</span>
          <span className={styles.filter}>擴充包 變換假面</span>
        </div> */}
        <div className={styles.header}>
          <div className={styles.result}>
            <span style={{ color: "var(--text5)", fontWeight: "500" }}>
              {searchValue?.card_name
                ? `"${searchValue?.card_name.trim()}"`
                : "全部"}
            </span>
            搜尋結果({cardSearchProps.data?.pages[0]?.total || 0})
          </div>
          <div
            className={styles.sort}
            onClick={() => {
              Dialog.alert({
                title: "提示",
                content: "近期更新",
                closeOnMaskClick: true,
              });
            }}
          >
            <img
              className={styles.sortImg}
              src={`/images/sort.png`}
              alt="sort"
            />
            <span className={styles.sortText}>排序</span>
          </div>
        </div>
        {cardSearchProps.isCardSearchLoading ? (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <DotLoading />
            <span>加載中</span>
          </div>
        ) : !cardSearchProps.data?.pages[0] ? (
          <ErrorBlock status="empty" />
        ) : (
          <>
            <div className={`${styles.cardArea} card-area`}>
              <PullToRefresh
                onRefresh={async () => {
                  queryClient.resetQueries({
                    queryKey: [queryKey.CARD, params],
                  });
                  await cardSearchProps.refetchCardSearch();
                }}
                renderText={(status) => {
                  return <div>{statusRecord[status]}</div>;
                }}
              >
                {cardSearchProps.data?.pages?.map((perPageData, pageIndex) => {
                  return perPageData?.data?.map((card, index) => {
                    return (
                      <div
                        className={styles.card}
                        key={index}
                        onClick={() => {
                          isSelected(card.card_id)
                            ? handleRemoveCard(card.card_id)
                            : handleAddCard(card);
                        }}
                      >
                        <Magnifier
                          children={
                            <div className={styles.cardImgArea}>
                              <LazyLoadImage
                                className={styles.cardImg}
                                src={card.image}
                                alt={card.card_name}
                              />
                              <div
                                className={`${styles.cardMask} ${
                                  isSelected(card.card_id) && styles.selected
                                }`}
                              >
                                <span className={styles.cardMaskText}>
                                  已選擇
                                </span>
                              </div>
                            </div>
                          }
                          card={card}
                        ></Magnifier>
                      </div>
                    );
                  });
                })}
              </PullToRefresh>
            </div>
            {!cardSearchProps.hasNextPage && (
              <Footer
                label="没有更多了"
                style={{ backgroundColor: "var(--homeBg)" }}
              ></Footer>
            )}
          </>
        )}
        <div
          ref={observer}
          className={styles.loadingContainer}
          style={{ display: cardSearchProps.hasNextPage ? "flex" : "none" }}
        >
          <div style={{ textAlign: "center" }}>
            <DotLoading />
            <span>加載中</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardList;
