import { API_DOMAIN, PROTOCOL } from "../config";
import req from "./https";

export const requestCardSearch = (
  method,
  path,
  data = null,
  needAuth = false
) => req(method, `${PROTOCOL}://${API_DOMAIN}${path}`, data, needAuth);

export const requestCardSearchNewest = (
  method,
  path,
  data = null,
  needAuth = false
) => req(method, `${PROTOCOL}://${API_DOMAIN}${path}`, data, needAuth);

export const requestCardSeries = (
  method,
  path,
  data = null,
  needAuth = false
) => req(method, `${PROTOCOL}://${API_DOMAIN}${path}`, { ...data, needAuth });

export const requestCardHot = (method, path, data = null, needAuth = false) =>
  req(method, `${PROTOCOL}://${API_DOMAIN}${path}`, { ...data, needAuth });

export const requestPackOrder = (method, path, data = null, needAuth = false) =>
  req(method, `${PROTOCOL}://${API_DOMAIN}${path}`, { ...data, needAuth });

export const requestSignUp = (method, path, data = null, needAuth = false) =>
  req(method, `${PROTOCOL}://${API_DOMAIN}${path}`, { ...data, needAuth });

export const requestLogin = (method, path, data = null, needAuth = false) =>
  req(method, `${PROTOCOL}://${API_DOMAIN}${path}`, { ...data, needAuth });

export const requestGoogleLogin = (
  method,
  path,
  data = null,
  needAuth = false
) => req(method, `${PROTOCOL}://${API_DOMAIN}${path}`, { ...data, needAuth });

export const requestQueryOrder = (
  method,
  path,
  data = null,
  needAuth = false
) => req(method, `${PROTOCOL}://${API_DOMAIN}${path}`, { ...data, needAuth });

export const requestDeleteOrder = (
  method,
  path,
  data = null,
  needAuth = false
) => req(method, `${PROTOCOL}://${API_DOMAIN}${path}`, { ...data, needAuth });

export const requestOrderList = (method, path, data = null, needAuth = false) =>
  req(method, `${PROTOCOL}://${API_DOMAIN}${path}`, { ...data, needAuth });
