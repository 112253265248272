import { MoreOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import {
  Button,
  DotLoading,
  Grid,
  ImageViewer,
  Mask,
  Popover,
} from "antd-mobile";
import html2canvas from "html2canvas";
import { useEffect, useRef, useState } from "react";
import { Header } from "../../../../component";
import { TYPE } from "../../../../enumerations/type";
import { moneyFormat } from "../../../../plugins/common";
import ActionArea from "../ActionArea";
import styles from "./style.module.scss";
import "./style.scss";

const OrderPhotoDrawer = ({
  open,
  setOpenOrderPhotoDrawer,
  items = [],
  type,
  onClick,
  loading,
  setLoading,
  orderData,
  isOpenDrawer,
  isLoading,
  isRefetching,
  actionButtons,
  popupActions,
}) => {
  const [visible, setVisible] = useState(true);
  const [imgVisible, setImgVisible] = useState(false);
  const [demoImage, setDemoImage] = useState(null);
  const printRef = useRef();
  const orderId = window.location.pathname.split("/")[2];
  const getImg = async (node) => {
    if (!node) {
      return;
    }
    await html2canvas(node, {
      useCORS: true,
    }).then(async (canvas) => {
      const data = canvas.toDataURL("image/jpg");
      setDemoImage(data);
      var img = new Image();
      img.src = data;
      img.setAttribute("class", `${styles.exportImg}`);
      document.getElementById("export-img-container").appendChild(img);
      setVisible(false);
    });
  };
  const renderMultiplePhoto = () => {
    let result = [];
    for (let i = 0; i < Math.ceil(items.length / 12); i++) {
      const _items = items.slice(i * 12, i * 12 + 12);
      while (_items.length < 12) {
        _items.push({ ..._items[_items.length - 1], disable: true });
      }
      result.push(
        <div className={styles.container} id="node" key={i}>
          <div
            className={styles.cardContainer}
            style={{ backgroundColor: TYPE[type]?.bgColor }}
          >
            <div
              className={styles.header}
              style={{ backgroundColor: TYPE[type]?.headerBgColor }}
            >
              <div className={styles.type}>{TYPE[type]?.text}</div>
            </div>
            <div className={styles.cardList}>
              <Grid columns={4} gap={[0, 2]}>
                {_items.map((item, index) => {
                  return (
                    <Grid.Item
                      key={index}
                      style={{ opacity: item.disable ? 0 : 1 }}
                    >
                      <div className={styles.card}>
                        <img
                          className={styles.image}
                          src={item.image}
                          alt={item.card_name}
                        />
                        <div className={styles.cardData}>
                          <div
                            className={styles.comment}
                            data-stroke={item.comment ? item.comment : "-"}
                          >
                            {item.comment ? item.comment : "-"}
                          </div>
                          <div
                            className={styles.amount}
                            data-stroke={moneyFormat(item.amount)}
                          >
                            {moneyFormat(item.amount)}
                          </div>
                          <div
                            className={styles.quota}
                            style={{ backgroundColor: TYPE[type]?.bgColor }}
                          >
                            <div className={styles.quotaValue}>
                              {item.quota}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid.Item>
                  );
                })}
              </Grid>
            </div>
          </div>
        </div>
      );
    }
    return result;
  };
  const renderPhoto = () => {
    return (
      <div className={styles.container} id="node" ref={printRef}>
        <div
          className={styles.borderContainer}
          style={{
            background: `-webkit-linear-gradient(${TYPE[type]?.bgColor1},${TYPE[type]?.bgColor2})`,
          }}
        >
          <div className={styles.cardContainer}>
            <div className={styles.header}>
              <div
                className={styles.type}
                style={{
                  //TODO
                  // background: `linear-gradient(${TYPE[type]?.headerTextColor1},${TYPE[type]?.headerTextColor2}) `,
                  // backgroundClip: "text",
                  color: TYPE[type]?.headerTextColor2,
                }}
              >
                {TYPE[type]?.text}
              </div>
              <div className={styles.comment}>
                {orderData?.content.pack_comment}
              </div>
            </div>
            <div className={styles.cardList}>
              <Grid columns={5} gap={[4, 0]}>
                {items.map((item, index) => {
                  return (
                    <Grid.Item key={index}>
                      <div className={styles.cardContainer}>
                        <div className={styles.card}>
                          <img
                            className={styles.image}
                            src={item.image}
                            alt={item.card_name}
                          />
                          <div className={styles.cardData}>
                            <div
                              className={styles.comment}
                              data-stroke={item.comment ? item.comment : ""}
                              style={{ opacity: !item.comment && 0 }}
                            >
                              {item.comment}
                            </div>
                            <div
                              className={styles.amount}
                              data-stroke={
                                moneyFormat(item.amount) !== "0"
                                  ? moneyFormat(item.amount)
                                  : ""
                              }
                              style={{ opacity: item.amount === "0" && 0 }}
                            >
                              {moneyFormat(item.amount)}
                            </div>
                            <div
                              className={styles.quota}
                              style={{
                                background: `linear-gradient(${TYPE[type]?.bgColor1},${TYPE[type]?.bgColor2})`,
                              }}
                            >
                              <div className={styles.quotaValue}>
                                {item.quota}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.name}>{item.card_name}</div>
                      </div>
                    </Grid.Item>
                  );
                })}
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  };
  useEffect(() => {
    const AsyncGetImg = async () => {
      await getImg(printRef?.current);
    };
    if (!isRefetching && !isLoading) {
      setTimeout(() => {
        AsyncGetImg();
      }, [500]);
    }
  }, [isLoading, isRefetching]);
  return (
    <Drawer
      open={open}
      extra={null}
      closeIcon={null}
      width="100%"
      className="order-photo-drawer"
      onClose={null}
    >
      <Mask
        color="white"
        visible={visible}
        // onMaskClick={() => setVisible(false)}
      >
        <div style={{ textAlign: "center", padding: "60px" }}>
          <DotLoading />
          <span>圖片加載中，請稍候</span>
        </div>
      </Mask>
      <Header
        title={"商品圖"}
        extra={
          <div style={{ display: "flex" }}>
            <Button
              color="primary"
              fill="none"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setImgVisible(true);
              }}
              style={{ whiteSpace: "nowrap" }}
            >
              預覽
            </Button>
            <Popover.Menu
              actions={popupActions.map((action) => ({
                ...action,
                icon: null,
              }))}
              placement="bottom-end"
              trigger="click"
              mode="dark"
            >
              <MoreOutlined style={{ fontSize: "16px", cursor: "pointer" }} />
            </Popover.Menu>
          </div>
        }
        drawer={true}
        toPage={"/order"}
        setOpenOrderPhotoDrawer={setOpenOrderPhotoDrawer}
        isOpenDrawer={isOpenDrawer}
      />
      <div style={{ position: "relative" }}>
        <div className={styles.drawerBody}>{renderPhoto()}</div>

        <div
          className={styles.exportImgContainer}
          id="export-img-container"
        ></div>
      </div>
      <ActionArea
        text={
          <span>下載圖片{loading && <DotLoading color="var(--white)" />}</span>
        }
        items={actionButtons}
        loading={loading}
        onClick={() => {
          const node = document.querySelectorAll("#node");
          setLoading(true);
          onClick(node);
        }}
      />
      <ImageViewer
        classNames={{
          mask: "customize-mask",
          body: "customize-body",
        }}
        image={demoImage}
        visible={imgVisible}
        onClose={() => {
          setImgVisible(false);
        }}
      />
    </Drawer>
  );
};

export default OrderPhotoDrawer;
