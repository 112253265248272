import { createContext, useContext, useState } from "react";
import { PreviewCard } from "../component";

const PreviewCardContext = createContext({
  showPrev: false,
  cardSrc: null,
  setShowPrev: () => {},
  setCardSrc: () => {},
});

export const PreviewCardContextProvider = ({ children }) => {
  const [showPrev, setShowPrev] = useState(false);
  const [cardSrc, setCardSrc] = useState(null);

  return (
    <PreviewCardContext.Provider
      value={{
        showPrev,
        cardSrc,
        setShowPrev,
        setCardSrc,
      }}
    >
      {children}
      <PreviewCard
        showPrev={showPrev}
        setShowPrev={setShowPrev}
        src={cardSrc}
      />
    </PreviewCardContext.Provider>
  );
};
export const usePreviewCard = () => {
  const Props = useContext(PreviewCardContext);
  return Props;
};
